import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { compact, get } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import Divider from '../../components/Divider';
import Pagination from '../../components/Pagination';
import Card from '../../components/Card';
import SEO from '../../components/SEO/SEO';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import { PromotionsIndexQuery } from '../../../graphql.schema';
import { GlobalPageContext, PaginationContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './index.module.scss';

type PageContextType = GlobalPageContext & PaginationContext;

const PromotionsIndex: React.FC<PageProps<PromotionsIndexQuery, PageContextType>> = ({ data, pageContext }) => {
  const { promotions } = data.strapi;
  const pages = get(data, 'strapi.pages', []);
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const intl = useIntl();

  if (!websiteLocale) return null;

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={pages[0]?.seo?.metaTitle ?? pages[0]?.pageSettings?.title ?? intl.formatMessage({ id: 'seo.promotions.title' })}
        description={pages[0]?.seo?.metaDescription ?? pages[0]?.pageSettings?.metaDescription ?? intl.formatMessage({ id: 'seo.promotions.description' })}
        image={pages[0]?.seo?.metaImage?.url}
        avoidIndexing={pages[0]?.pageSettings?.avoidIndexing ?? false}
        canonicalURL={pages[0]?.seo?.canonicalURL}
        keywords={pages[0]?.seo?.keywords}
        metaSocial={pages[0]?.seo?.metaSocial}
        structuredData={pages[0]?.seo?.structuredData}
      />
      <div className={classnames('container', styles.root)}>
        <div className="columns">
          <div className="column is-5">
            <h1 className="title color-a">
              <FormattedMessage id="promotionsIndexHeading" defaultMessage="Promotions" />
            </h1>
            <p className="is-text-1 black-2">
              <FormattedMessage id="promotionsIndexDescription" />
            </p>
          </div>
        </div>

        <Divider paddingTop="75px" marginBottom="25px" />

        {!promotions?.length ? (
          <div className={classnames('column is-5 p-0 mt-10', styles.noPromotions)}>
            <h4 className="title is-4">
              <FormattedMessage id="promotionsIndexDescription" />
            </h4>
            <p>
              <FormattedMessage id="noPromotionsIndexDescription" />
            </p>
          </div>
        ) : null}
        <div className={styles.gridWrapper}>
          {compact(promotions).map(({ documentId, title, slug }) => {
            if (!slug) return null;
            return (
              <Card
                urlText={intl.formatMessage({ id: 'promotionsCardText', defaultMessage: 'View Promotion' })}
                slugBase={pageContext.pathPrefixes.promotions}
                key={`promotion-${documentId}`}
                title={title ?? 'N/A'}
                slug={slug}
              />
            );
          })}
        </div>
        <div className={classnames('page-components')}>
          {pages[0]?.components &&
            pages[0]?.components?.map((component: any, idx: any) =>
              component ? (
                <ComponentRenderer
                  hasRTL={websiteLocale?.hasRightToLeftLanguage}
                  component={component}
                  key={`page-component-${component.__typename}-${idx}`}
                />
              ) : null,
            )}
        </div>
        <Pagination rootPath={pageContext.pathPrefixes.promotions} pageContext={pageContext} />
      </div>
      {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />}
    </LayoutWrapper>
  );
};

export default intlWrapperHOC(v4tov3HOC(PromotionsIndex));

export const query = graphql`
query PromotionsIndex($websiteLocaleId: ID!, $limit: Int!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    promotions(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}}
      locale: $locale
      sort: "publishedAt:asc"
      pagination: {limit: $limit}
    ) {
      data {
        documentId
        attributes {
          title
          slug
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
        }
      }
    }
    pages(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}, index_page: {eq: "Promotions"}}
      locale: $locale
    ) {
      data {
        documentId
        attributes {
          title
          slug
          components {
            ...PageComponents
          }
          page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;
